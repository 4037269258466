import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Images/Company_logo_6a.png';

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="text-mainBlue py-5 mb-10">
            <div className={`max-w-7xl mx-auto flex justify-between ${isOpen ? "items-start" : "items-center"} px-4 sm:px-6 lg:px-8`}>
                <div className="flex items-center">
                    <Link to="/" className="text-xl font-bold">
                        <img src={logo} alt="logo" className="w-32 sm:w-48 rounded-xl transition-all ease-in-out duration-300 hover:scale-105" />
                    </Link>
                </div>
                <div className='flex flex-col'>
                <div className="flex items-center justify-end pr-2 sm:hidden">
                      <button onClick={toggleMenu} className="text-mainBlue focus:outline-none">
                          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              {isOpen ? (
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                              ) : (
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                              )}
                          </svg>
                      </button>
                  </div>

                  <div className={`flex-col justify-end sm:flex-row sm:flex space-x-0 sm:space-x-4 font-extrabold text-base sm:text-sm ${isOpen ? 'flex' : 'hidden'} sm:block`}>

                  <Link to="/" className="flex justify-end hover:text-mainBlue hover:opacity-70 px-3 py-2 rounded-md transition-all ease-in-out duration-300">
                      Home
                  </Link>
                  <Link to="/about" className="flex justify-end hover:text-mainBlue hover:opacity-70 px-3 py-2 rounded-md transition-all ease-in-out duration-300">
                      Over ons
                  </Link>
                  <Link to="/services" className="flex justify-end hover:text-mainBlue hover:opacity-70 px-3 py-2 rounded-md transition-all ease-in-out duration-300">
                      Onze diensten
                  </Link>
                  <Link to="/contact" className="flex justify-center hover:text-mainBlue hover:opacity-80 w-fill px-3 py-2 rounded-md bg-mainGreen transition-all ease-in-out duration-300">
                      Contact
                  </Link>
                  </div>
                </div>




            </div>
        </nav>
    );
};

export default NavBar;

import React, { useState } from 'react';
import { sendAeriusEmail, sendOtherEmail } from '../services/api';
import GlobalForm from './GlobalForm';
import AeriusForm from './AeriusfForm';
import OtherForm from './OtherForm';

const EmailForm = () => {

    const [globalData, setGlobalData] = useState('');

    const [selectedProjectType, setSelectedProjectType] = useState('');
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    const handleGlobalFormChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(e.target.value);
        setGlobalData({ ...globalData, 
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleProjectTypeChange = (e) => {
      setSelectedProjectType(e.target.value)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = { ...globalData, projectType: selectedProjectType}

            let result;
            
            switch (selectedProjectType) {
              case 'Aerius':
                result = await sendAeriusEmail(formData);
                break;
                case 'Other':
                result = await sendOtherEmail(formData);
                break;
              default:
                throw new Error('Unknown project type');
            }
            setResponse(result.data);
            setError(null);
        } catch (error) {
            setError(error.message);
            setResponse(null);
        }
    };

    const renderProjectForm = () => {
      switch (selectedProjectType) {
          case 'Aerius':
              return <AeriusForm globalData={globalData} handleGlobalChange={handleGlobalFormChange} />;
              case 'Other':
              return <OtherForm globalData={globalData} handleGlobalChange={handleGlobalFormChange} />;
          default:
              return null;
      }
  };

    return (
      <div className="max-w-3xl mx-auto px-4 py-8 h-max relative">
      <h1 className="text-3xl font-bold mb-4 text-mainBlue">Offerte aanvraag</h1>
      <p className='my-5'>Of vul onze online offerte aanvraag in en laat ons van te voren kijken wat wij kunnen betekenen in uw aanvraag!</p>
      <form onSubmit={handleSubmit} className="space-y-9 h-max relative">
  

          <GlobalForm globalData={globalData} handleGlobalChange={handleGlobalFormChange} handleProjectTypeChange={handleProjectTypeChange} selectedProjectType={selectedProjectType} />

          {selectedProjectType && renderProjectForm()}


            
          

        <button
          type="submit"
          className="inline-block px-6 py-2 bg-mainGreen text-mainBlue font-bold rounded-md shadow-md hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all ease-in-out duration-300"
        >
          Vraag aan!
        </button>
      </form>
      {response && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">Response from server:</h2>
          <pre className="bg-gray-100 p-4 rounded-md">{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
      {error && <div className="text-red-600 mt-4">{error}</div>}
    </div>
      );
};

export default EmailForm;


import React from 'react';
import logo6 from '../Images/Company_logo_6c.png';

function About() {
  return (
    <div className='mx-4 sm:mx-12'>

      <div className="flex flex-col justify-center w-full bg-white p-4 sm:p-8 rounded-lg shadow-lg transition-all gap-x-5">

        <div className='flex flex-col justify-center'>
          <h1 className="text-2xl sm:text-4xl font-bold text-mainBlue mb-4 sm:mb-6">Welkom bij de omgevings specialist!</h1>
          <p className="text-base sm:text-lg text-gray-700 mb-2 sm:mb-4">
            Wij zijn een klein team van gepassioneerde professionals die zich inzetten voor het leveren van hoogwaardige diensten op het gebied van Aerius berekeningen en stikstofdepositieberekeningen voor kleine bouwprojecten. Ons bedrijf is ontstaan uit de visie om onze klanten te ondersteunen bij het realiseren van hun projecten binnen de particuliere woningbouw terwijl we tegelijkertijd bijdragen aan een duurzame toekomst.
          </p>
          <p className="text-base sm:text-lg text-gray-700 mb-2 sm:mb-4">
            Onze missie is om bedrijven en particulieren te ondersteunen en vooruit te helpen bij hun vergunningsaanvraag door stikstofberekeningen te leveren om zo een soepele bouwaanvraag te kunnen doen. Bij de Omgevingsspecialist zijn we erg gedreven doordat deze markt in onze ogen deze extra hand extra kan gebruiken omdat hier de meest uiteenlopende bijzondere projecten uit voortvloeien en wij als liefhebbers graag ons steentje bij willen dragen aan de bouwwereld. Dit streven we na door hoogwaardige, nauwkeurige stikstofdepositieberekeningen te maken waardoor onze klant gerust kan gaan bouwen. Onze toewijding aan de bouw en duurzaamheid maakt ons een betrouwbare partner voor al uw Aerius-berekeningen of stikstofdepositieberekeningen.
          </p>
          <p className="text-base sm:text-lg text-gray-700 mb-2 sm:mb-4">
            Bij de Omgevingsspecialist geloven we in een soepele en laagdrempelige samenwerking waarbij wij onze expertise kunnen inzetten voor uw project. Ons team hecht veel waarde aan klanttevredenheid en we streven er altijd naar om een steentje bij te kunnen dragen aan een soepele vergunningsaanvraag en voorspoedig bouwproject.
          </p>
          <p className="text-base sm:text-lg text-gray-700 mb-2 sm:mb-4">
            Door een snel traject van aanvraag tot berekening onderscheiden wij ons van anderen in de sector. Onze focus op een hoge kwaliteit op korte termijn stelt ons in staat om klanten snel resultaat te leveren. Dit komt doordat de Omgevingsspecialist gespecialiseerd is in particuliere woningbouw en hier dan ook hun kracht voor goed werk vandaan haalt.
            Bij de Omgevingsspecialist staan we klaar om u te ondersteunen bij uw projecten en om een betrouwbare partner te zijn in uw streven naar een succesvol bouwproject. Vraag een offerte aan en ontdek hoe we u kunnen helpen bij uw Aerius berekeningen en stikstofdepositieberekeningen.
          </p>
          <div className='flex justify-center pt-5'>
          <img src={logo6} alt='Logo' className='w-full md:w-1/3' />
          </div>
        </div>

      </div>
    </div>
  );
}

export default About;

import React from 'react';

function Agreement() {
    return (
      <div className='mx-12'>

      <div className="flex justify-center flex-col w-full bg-white p-8 rounded-lg shadow-lg transition-all gap-x-5">

        <div className='flex flex-col justify-center'>
          <h1 className="text-xl font-bold text-mainBlue mb-6 sm:text-4xl">Gebruiksvoorwaarden</h1>
          <p className="text-md text-gray-700 mb-4 sm:text-lg">
                    Bij de Omgevingsspecialist streven we ernaar om u de best mogelijke service te
          bieden en u te ondersteunen bij het voldoen aan de eisen voor uw bouwproject. We
          willen benadrukken dat we een breed scala aan mogelijkheden bieden om ervoor te
          zorgen dat u kunt voldoen aan de vereisten voor stikstofdepositie, inclusief advies
          over het gebruik van materieel die de uitstoot verminderen.
          Echter, het is belangrijk om op te merken dat hoewel we ons uiterste best doen om
          nauwkeurige en betrouwbare adviezen te geven en Aerius-berekeningen uit te
          voeren, we niet aansprakelijk kunnen worden gesteld voor het niet naleven van deze
          adviezen of voor de uitkomsten van de berekeningen. Onze adviezen en
          berekeningen zijn gebaseerd op de informatie en wetgeving die op dat moment
          beschikbaar is gesteld, en kunnen hiermee afhankelijk zijn van verschillende
          variabelen en omstandigheden die buiten onze controle liggen.
          We willen u er echter op wijzen dat we altijd ons uiterste best zullen doen om u te
          voorzien van professioneel advies. We zijn hier om u te helpen en samen te werken
          aan een succesvolle uitkomst voor uw bouwaanvraag en project.
          Mocht u nog vragen of opmerkingen hebben, aarzel dan niet om contact met ons op
          te nemen. We staan klaar om u te helpen.
          Neem contact met ons op om te ontdekken hoe we u kunnen helpen bij uw
          bouwprojecten. Een offerte aanvragen via de website is net zo makkelijk!
            </p>
        </div>
      </div>
    </div>

    );
  }
  
  export default Agreement;
import axios from 'axios';

const api = axios.create({
    baseURL: 'https://localhost:7121/api/',
})

export const sendAeriusEmail = async (data) => {
    try {
        const response = await api.post('Email/Aerius', data);
        return response.data;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
};

export const sendOtherEmail = async (data) => {
    try {
        const response = await api.post('Email/Other', data);
        return response.data;
    } catch(error) {
        console.log('Error:', error);
        throw error;
    }
}
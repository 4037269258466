import React from 'react';
import EmailForm from '../components/EmailForm';
import ContactForm from '../components/ContactForm';

function Contact() {
  return (
    <div>
      <div className="">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ContactForm />
            <div className="bg-white shadow-md rounded-lg overflow-hidden flex items-center justify-center transition-all hover:scale-105 ease-in-out duration-200  sm:mx-0 mx-10">
                <div className="p-6">
                    <EmailForm />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
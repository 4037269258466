import React from 'react';
import logo3 from '../Images/logo_3.png';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className="min-h-screen flex flex-col items-start justify-center gap-y-10 px-4 sm:px-8 md:px-16">
            <div className="flex flex-col sm:flex-row justify-center w-full bg-white p-6 sm:p-8 rounded-lg shadow-lg transition-all hover:scale-105 ease-in-out duration-300 gap-5">
                <img src={logo3} alt="logo" className="hidden md:block w-auto"/>
                <div className="flex flex-col justify-center w-full sm:w-2/3">
                    <h1 className="text-2xl sm:text-4xl font-bold text-mainBlue mb-4 sm:mb-6">
                        Welkom bij de omgevings specialist,
                    </h1>
                    <p className="text-base sm:text-lg text-gray-700 mb-4">
                        uw betrouwbare partner voor uw Aerius berekeningen en stikstofdepositieberekeningen voor kleinschalige bouwprojecten. Wij zijn gespecialiseerd in het leveren van hoogwaardige berekeningen die voldoen aan de eisen van zowel particuliere als zakelijke klanten. Snel en gemakkelijk aangevraagd!
                    </p>
                    <button className="bg-mainBlue hover:bg-blue-600 w-fit text-white py-2 px-4 sm:px-6 rounded-lg transition-all ease-in-out duration-300 mx-auto sm:mx-0">
                        <Link to="/contact">
                            Neem contact op!
                        </Link>
                    </button>
                </div>
            </div>

            <div className="flex flex-wrap justify-center gap-10 pb-10">
                <div className="flex-1 w-full sm:w-1/3 bg-white p-6 sm:p-8 rounded-lg shadow-lg transition-all mb-4 hover:scale-105 ease-in-out duration-300">
                    <h1 className="text-2xl sm:text-4xl font-bold text-mainBlue mb-4 sm:mb-6">Waarom de omgevingsspecialist?</h1>
                    <div className="bg-mainGreen bg-opacity-20 p-6 sm:p-8 rounded-lg shadow-lg mb-4">
                        <h1 className="font-bold text-lg sm:text-xl pb-3">Onze expertise</h1>
                        <p className="text-sm sm:text-base">Bij de Omgevingsspecialist begrijpen we de complexiteit van stikstofdepositieberekeningen en de impact ervan op uw projecten. Ons kleine team van professionals beschikt over de ervaring in het uitvoeren van Aerius-berekeningen voor diverse bouwprojecten. We hebben bewezen resultaten geleverd die voldoen aan de geldende voorschriften en normen. Omdat we een klein bedrijf zijn kunnen we na de offerte aanvraag op korte termijn de juiste berekening voor u in orde maken waardoor u altijd kunt starten met bouwen!</p>
                    </div>
                    <div className="bg-mainGreen bg-opacity-20 p-6 sm:p-8 rounded-lg shadow-lg mb-4">
                        <h1 className="font-bold text-lg sm:text-xl pb-3">Klantgerichte Benadering</h1>
                        <p className="text-sm sm:text-base">Bij de Omgevingsspecialist streven we ernaar om elke klant een gepersonaliseerde en professionele service te bieden. We weten dat elk project uniek is en daarom werken we graag op een manier die perfect aansluiten bij de specifieke vereisten van onze klanten. Zo kunnen we de stikstof berekeningen voor uw bouwproject zo nauwkeurig mogelijk aan laten sluiten op de realiteit. Ons team zal ten alle tijden proberen te zorgen voor een aangename samenwerking en snel resultaat te leveren.</p>
                    </div>
                    <div className="bg-mainGreen bg-opacity-20 p-6 sm:p-8 rounded-lg shadow-lg mb-4">
                        <h1 className="font-bold text-lg sm:text-xl pb-3">Transparantie en Betrouwbaarheid</h1>
                        <p className="text-sm sm:text-base">Transparantie en betrouwbaarheid staan bij ons hoog. We streven ernaar om open en duidelijk te communiceren met onze klanten gedurende het proces. U kunt erop vertrouwen dat wij ons uiterste best doen om nauwkeurige en tijdige resultaten te leveren die aan uw verwachtingen voldoen. Omdat project informatie gevoelig kan zijn zullen wij dan ook ten alle tijden uiterst voorzichtig omgaan met de gedeelde informatie die nodig is voor een complete stikstofberekening.</p>
                    </div>
                    <div className="flex justify-center">
                        <button className="bg-mainBlue hover:bg-blue-600 text-white py-2 px-4 sm:px-6 rounded-lg transition-all ease-in-out duration-300">
                            <Link to="/about">
                                Leer ons beter kennen
                            </Link>
                        </button>
                    </div>
                </div>

                <div className="flex-1 w-full sm:w-2/3 bg-white p-6 sm:p-8 rounded-lg shadow-lg transition-all hover:scale-105 ease-in-out duration-300">
                    <div className="flex flex-col sm:flex-row w-full justify-between items-center">
                        <div className="flex flex-col justify-center w-full sm:w-3/5">
                            <h1 className="text-2xl sm:text-4xl font-bold text-mainBlue mb-4 sm:mb-6">Onze diensten</h1>
                            <p className="text-base sm:text-lg text-gray-700">
                                Ontdek onze veelzijdige diensten die zijn ontworpen om aan al uw behoeften te voldoen. Wij bieden op maat gemaakte oplossingen om uw projecten succesvol te maken.
                            </p>
                        </div>
                        <img src={logo3} alt="logo" className="hidden md:block w-48 h-auto"/>
                    </div>
                    <div className="flex flex-col gap-10 py-6 sm:py-10">
                        <div className="flex flex-col sm:flex-row justify-evenly gap-6 sm:gap-10">
                            <div className="flex-1 bg-mainGreen bg-opacity-20 p-6 sm:p-8 rounded-lg shadow-lg">
                                <h1 className="font-bold text-xl sm:text-3xl pb-3">Aerius berekening</h1>
                                <p className="text-sm sm:text-lg">Een Aerius berekening is een methode om de stikstofdepositie op natuurgebieden te evalueren door menselijke activiteiten zoals industrie, landbouw, verkeer en bouw te analyseren. Het maakt gebruik van geavanceerde modellen en databanken om de impact van stikstofuitstoot op biodiversiteit en gevoelige habitats te voorspellen, wat cruciaal is voor milieubeoordelingen en vergunningen.</p>
                            </div>
                            <div className="flex-1 bg-mainGreen bg-opacity-20 p-6 sm:p-8 rounded-lg shadow-lg">
                                <h1 className="font-bold text-xl sm:text-3xl pb-3">Milieu prestatie berekening</h1>
                                <p className="text-sm sm:text-lg">Een milieu prestatie berekening is een gestructureerde methode om de milieueffecten van een product, proces of dienst gedurende de gehele levenscyclus te evalueren. Deze berekening omvat de analyse van grondstoffen, energieverbruik, emissies naar lucht, water en bodem, afvalproductie en andere milieu-impactfactoren. Het doel is om inzicht te krijgen in de totale milieubelasting en om basis te bieden voor verbeteringen in duurzaamheid en milieuprestaties.</p>
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row justify-evenly gap-6 sm:gap-10">
                            <div className="flex-1 bg-mainGreen bg-opacity-20 p-6 sm:p-8 rounded-lg shadow-lg">
                                <h1 className="font-bold text-xl sm:text-3xl pb-3">Gepersonaliseerde opdracht</h1>
                                <p className="text-sm sm:text-lg">Indien u een andere opdracht heeft die wellicht binnen ons expertisegebied valt dan onze gebruikelijke diensten, nodigen wij u graag uit om contact met ons op te nemen. Mogelijk kunnen wij u van dienst zijn met ons breed scala aan vaardigheden en ervaring.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center mt-auto">
                        <button className="bg-mainBlue hover:bg-blue-600 text-white py-2 px-4 sm:px-6 rounded-lg transition-all ease-in-out duration-300">
                            <Link to="/services">
                                Lees meer over onze diensten
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;

import React from 'react';

const OtherForm = ({ globalData, handleGlobalChange }) => {
    return (
        <div className=''>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4'>

                <div className="relative md:col-span-2">
                    <textarea
                        name="description"
                        value={globalData.description}
                        onChange={handleGlobalChange}
                        required
                        className="block w-full h-32 py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500 resize-none"
                    ></textarea>
                    <label className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white">Beschrijving</label>
                </div>

            </div>
        </div>
    );
};

export default OtherForm;
import React from 'react';


const ContactForm = () => {
  return (
    <div>
        <div className='grid grid-rows-1 gap-4 justify-center'>

          <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
            <div className='grid grid-cols gap-2 justify-start'>
            <i class='bx bxs-contact mr-auto font-extrabold text-3xl' ></i>
              <h3 className='mr-auto font-extrabold'>Neem contact op!</h3>
            </div>
          </div>

          <a href="mailto:l.klaassen@omgevingsspecialist.nl" className="bg-white p-8 rounded-lg shadow-md max-w-md w-full hover:scale-110 transition-all ease-in-out duration-300s">
            <div className='grid grid-cols gap-2 justify-start'>
            <i class='bx bx-envelope mr-auto font-extrabold text-3xl'></i>
              <h3 className='mr-auto font-extrabold'>Email</h3>
              <p className='text-blue-500 font-bold'>l.klaassen@omgevingsspecialist.nl</p>
            </div>
          </a>

          <a href="tel:+31612141001" className="bg-white p-8 rounded-lg shadow-md max-w-md w-full hover:scale-110 transition-all ease-in-out duration-300s">
            <div className='grid grid-cols gap-2 justify-start'>
            <i class='bx bxs-phone mr-auto font-extrabold text-3xl' ></i>
              <h3 className='mr-auto font-extrabold'>Telefoon</h3>
              <p className='text-blue-500 font-bold'>+31612141001</p>
            </div>
          </a>

        </div>
    </div>
  );
};

export default ContactForm;

import './App.css';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home'
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import Privacy from './pages/Privacy';
import Agreement from './pages/Agreement';


function App() {
  return (
    <BrowserRouter>
    <div className='flex flex-col App min-h-screen bg-mainGreen bg-opacity-20 overflow-x-hidden'>

      <NavBar/>
      <div className='h-auto'>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/privacy' element={<Privacy/>}/>
          <Route path='/agreement' element={<Agreement/>}/>
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      </div>
      <Footer/>



    </div>
    </BrowserRouter>
  );
}

export default App;

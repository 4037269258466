import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='w-full grid grid-cols-1 py-10 gap-y-5 text-lg text-mainBlue justify-center mt-auto'>
            <div className='flex justify-evenly font-extrabold'>
            <Link to="/privacy" className="hover:text-mainBlue hover:opacity-70 px-3 py-2 rounded-md transition-all ease-in-out duration-300">
            <p>Privacybeleid</p>
            </Link>
            <Link to="/agreement" className="hover:text-mainBlue hover:opacity-70 px-3 py-2 rounded-md transition-all ease-in-out duration-300">
            <p>Gebruiksvoorwaarden</p>
            </Link>
            </div>
            <div className='flex justify-center gap-3'>
                <a href="tel:+31612141001"><i class='bx bxs-phone font-extrabold text-3xl' ></i></a>
                <a href="mailto:l.klaassen@omgevingsspecialist.nl"><i class='bx bx-envelope font-extrabold text-3xl'></i></a>
            </div>
            <div className='flex justify-center'>

                <p>2024 Omgevingsspecialist. Alle rechten voorbehouden</p>

            </div>
        </div>
    );
}

export default Footer;
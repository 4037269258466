import React from 'react';

const GlobalForm = ({ globalData, handleGlobalChange, handleProjectTypeChange, selectedProjectType }) => {
    return (
        <div className='space-y-4 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-1'>
            <div className="relative md:col-span-2">
                <input type="email" name="email" value={globalData.email} onChange={handleGlobalChange} required
                    className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500" 
                />
                <label htmlFor="email_input" className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white rounded-b-md">Email</label>
            </div>
            <div className="relative">
                <input type="text" name="phoneNumber" value={globalData.phoneNumber} onChange={handleGlobalChange} required id="phoneNumber_input"
                    className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
                />
                <label htmlFor="phoneNumber_input" className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white rounded-b-md">Telefoon nummer</label>
            </div>
            <div className="relative">
                <input type="text" name="lastName" value={globalData.lastName} onChange={handleGlobalChange} required id="lastName_input"
                    className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
                />
                <label htmlFor="lastName_input" className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white rounded-b-md">Achternaam</label>
            </div>
            <div className="relative">
                <input type="text" name="companyName" value={globalData.companyName} onChange={handleGlobalChange} required id="companyName_input"
                    className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
                />
                <label htmlFor="companyName_input" className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white rounded-b-md">Bedrijfsnaam</label>
            </div>
            <select
            name="projectType"
            value={selectedProjectType}
            onChange={handleProjectTypeChange}
            required
            className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
            id="projectType_input"
            >
            <option value="">Selecteer opdracht</option>
            <option value="Aerius">Aerius berekening</option>
            <option value="Other">Anders</option>
            {/* Add more options as needed */}
            </select>
        </div>
    );
};

export default GlobalForm;
import React from 'react';

const AeriusForm = ({ globalData, handleGlobalChange }) => {
    return (
        <div className=''>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4'>

                <div className="relative">
                    <input type="text"
                        name="legalEntity"
                        value={globalData.legalEntity}
                        onChange={handleGlobalChange}
                        required
                        className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
                    />
                    <label className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white group rounded-b-md">
                        Rechtspersoon 
                        <i className='bx bx-help-circle text-m ml-1'></i>
                        <div className="absolute left-0 bottom-full w-48 p-2 bg-white border border-gray-300 rounded-lg shadow-lg text-xs text-gray-700 opacity-0 scale-0 transform transition-opacity duration-700 ease-in-out group-hover:opacity-100 group-hover:scale-100">
                            Wat is een Rechtspersoon?
                        </div>
                    </label>
                </div>

                <div className="relative">
                    <input type="text"
                        name="projectAddress"
                        value={globalData.projectAddress}
                        onChange={handleGlobalChange}
                        required
                        className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
                    />
                    <label className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white rounded-b-md">Project adres </label>
                </div>

                <div className="relative">
                    <input type="text"
                        name="buildingType"
                        value={globalData.buildingType}
                        onChange={handleGlobalChange}
                        required
                        className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
                    />
                    <label className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white rounded-b-md">Type gebouw</label>
                </div>

                <div className="relative">
                    <input type="text"
                        name="startYear"
                        value={globalData.startYear}
                        onChange={handleGlobalChange}
                        required
                        className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
                    />
                    <label className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white rounded-b-md">Start jaar</label>
                </div>

                <div className="relative">
                    <input type="text"
                        name="completionYear"
                        value={globalData.completionYear}
                        onChange={handleGlobalChange}
                        required
                        className="block w-full py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
                    />
                    <label className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white rounded-b-md">Jaar van afronding</label>
                </div>

                <div className="relative my-2">
                    <label className="inline-flex items-center rounded-b-md">
                        <input
                            type="checkbox"
                            name="demolish"
                            checked={globalData.demolish}
                            onChange={handleGlobalChange}
                            className="form-checkbox h-5 w-5 text-indigo-600"
                        />
                        <span className="ml-2 text-gray-700">Wordt er gesloopt?</span>
                    </label>
                </div>

                <div className="relative md:col-span-2">
                    <textarea
                        name="description"
                        value={globalData.description}
                        onChange={handleGlobalChange}
                        required
                        className="block w-full h-32 py-2 px-3 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500 resize-none"
                    ></textarea>
                    <label className="absolute left-3 -top-3 px-1 text-xs text-gray-500 bg-white group rounded-b-md">
                        Beschrijving
                        <i className='bx bx-help-circle text-m ml-1'></i>
                        <div className="absolute left-0 bottom-full w-48 p-2 bg-white border border-gray-300 rounded-lg shadow-lg text-xs text-gray-700 opacity-0 scale-0 transform transition-opacity duration-700 ease-in-out group-hover:opacity-100 group-hover:scale-100">
                            Wat is een Beschrijving?
                        </div>
                    </label>
                </div>


            </div>
        </div>
    );
};

export default AeriusForm;
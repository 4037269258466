import React from 'react';
import WIP from '../Images/inprogress.png'

function Privacy() {
    return (
      <div className='flex flex-col justify-center'> 
      <h2 className='text-4xl font-bold text-mainBlue'>Work in progres...</h2>
      <img src={WIP} alt="Work in progress" className='size-1/4 mr-auto ml-auto mt-10'/>
    </div>
    );
  }
  
  export default Privacy;